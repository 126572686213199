import React from "react"
import styled from "@emotion/styled"

const MobileTopDecorationWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: -10;
  filter: blur(2px);
  display: block;
  background-image: url("./images/TopRight500.svg");
  height: 165px;
  width: 94px;
  @media screen and (min-width: 1050px) {
    display: none;
  }
  @media screen and (min-width: 1400px) {
    display: none;
  }
`

const DesktopTopDecorationWrapper = styled.div`
  display: none;
  @media screen and (min-width: 1400px) {
    height: 100vw;
    width: 80vw;
    position: absolute;
    display: block;
    top: -150px;
    right: 0;
    z-index: -10;
    background-image: url("./images/TopRightMax.png");
    background-repeat: no-repeat;
  }
`

const BottomDecorationWrapper = styled.div`
  background-image: url("./images/BottomLeft500.svg");
  bottom: 0;
  display: block;
  filter: blur(2px);
  height: 221px;
  left: 0;
  margin-bottom: 100px;
  position: absolute;
  width: 86px;
  z-index: -10;
  @media screen and (min-width: 1050px) {
    display: none;
  }
  @media screen and (min-width: 1400px) {
    display: none;
  }
`

const Decorations = () => {
  return (
    <>
      <MobileTopDecorationWrapper />
      <DesktopTopDecorationWrapper />
      <BottomDecorationWrapper />
    </>
  )
}

export default Decorations
