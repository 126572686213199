// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chisiamo-js": () => import("./../../../src/pages/chisiamo.js" /* webpackChunkName: "component---src-pages-chisiamo-js" */),
  "component---src-pages-circolari-js": () => import("./../../../src/pages/circolari.js" /* webpackChunkName: "component---src-pages-circolari-js" */),
  "component---src-pages-contattaci-js": () => import("./../../../src/pages/contattaci.js" /* webpackChunkName: "component---src-pages-contattaci-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-servizi-js": () => import("./../../../src/pages/servizi.js" /* webpackChunkName: "component---src-pages-servizi-js" */),
  "component---src-templates-single-doc-js": () => import("./../../../src/templates/singleDoc.js" /* webpackChunkName: "component---src-templates-single-doc-js" */)
}

