import React, { useState } from "react"
import "../styles/reset.css"
import MenuContext from "./menuContext"
import Transitions from "./transitions"
import Decorations from "./decorations"
import Footer from "./footer"
import NavBar from "./navBar"

const Layout = ({ children, location }) => {
  const [menu, setMenu] = useState(false)
  const value = { menu, setMenu }

  return (
    <>
      <MenuContext.Provider value={value}>
        <NavBar location={location} />
        <Transitions location={location}>
          <Decorations />
          <main
            style={{
              maxWidth: 1050,
              paddingTop: 0,
              margin: "0 auto",
            }}
          >
            {children}
          </main>
        </Transitions>
        <Footer location={location} />
      </MenuContext.Provider>
    </>
  )
}

export default Layout
