import React from "react"
import NavBarMobile from "./navBarMobile"
import NavBarDesktop from "./navBarDesktop"
import Decorations from "./decorations"

const NavBar = ({ location }) => {
  return (
    <>
      <NavBarMobile location={location} />
      <NavBarDesktop location={location} />
    </>
  )
}

export default NavBar
