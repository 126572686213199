import styled from "@emotion/styled"

const Button = styled.button`
  align-items: center;
  background-color: ${props => (props.primary ? "#127FBF" : "#f5f7fa")};
  background-size: 300% 100%;
  background-position: left bottom;
  box-shadow: ${props =>
    props.primary
      ? "0px 3px 6px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.15);"
      : "none"};
  border: ${props => (props.primary ? "none" : "1px solid #9AA5B1;")};
  border-radius: 5px;
  color: ${props => (props.primary ? "#F5F7FA" : "#9AA5B1")};
  display: flex;
  font-size: 14px;
  font-weight: ${props => (props.primary ? "900" : "bold")};
  height: 42px;
  justify-content: center;
  margin-right: ${props => (props.hasRightMargin ? "16px" : "none")};
  //text-transform: uppercase;
  width: ${props => (props.isFullWidth ? "100%" : "48%")};
  transition: all 500ms ease;
  cursor: pointer;
  & a {
    transition: all 300ms ease;
    color: ${props => (props.primary ? "#f5f7fa" : "#9AA5B1")};
  }
  &:hover {
    background-color: ${props => (props.primary ? "#e2ecf5" : "#f5f7fa")};
    box-shadow: ${props =>
      props.primary
        ? "0px 3px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.25);"
        : "none"};
    border: ${props => (props.primary ? "none" : "1px solid #323F4B;")};
    & > a {
      color: #323f4b !important;
    }
  }
`
export default Button
