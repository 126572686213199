import React, { useState, useEffect, useContext } from "react"
import MenuContext from "./menuContext"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { MapPin, Phone, Printer, Mail } from "react-feather"

const Container = styled.div`
  min-height: 512px;
  background-color: #52606d;
  padding: 48px 20px 0;
  @media screen and (min-width: 1050px) {
    padding-top: 0;
    min-height: 384px;
  }
`

const Content = styled.div`
  @media screen and (min-width: 1050px) {
    min-width: 1050px;
    margin: 0 auto;
    min-height: 384px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const StudioLogo = styled.h1`
  margin: ${props => (props.hasMargin ? "5px 0 0" : "0")};
  font-size: 20px;
  color: #f5f7fa;
  font-weight: ${props => (props.thin ? "200" : "bold")};
  @media screen and (min-width: 1050px) {
    font-size: 20px;
    margin: ${props => (props.hasMargin ? "0 0 0" : "0")};
  }
`

const AdditionalInfo = styled.p`
  color: #e4e7eb;
  font-size: 15px;
  margin: 0;
  padding: 0;
`

const Info = styled.p`
  margin: 0;
  margin-left: 16px;
  padding: 0;
  color: #f5f7fa;
  font-size: 15px;
`

const BottomMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  & a {
    text-decoration: none;
  }
  @media screen and (min-width: 1050px) {
    flex: 1;
  }
`

const BottomMenuItem = styled.span`
  font-size: 12px;
  color: #f5f7fa;
  border-bottom: 0.5px solid #f5f7fa;
  @media screen and (min-width: 1050px) {
    font-size: 14px;
  }
`

const Copyright = styled.span`
  font-size: 9px;
  color: #9aa5b1;
  margin-bottom: 4px;
  @media screen and (min-width: 1050px) {
    flex: 2;
    font-size: 14px;
  }
`

const LeftSide = styled.div`
  @media screen and (min-width: 1050px) {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
`

const BottomBar = styled.div`
  border-top: 0.5px solid grey;
  @media screen and (min-width: 1050px) {
    flex: 3;
  }
`

const BottomBarContainer = styled.div`
  display: flex;
  height: 82px;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 1050px) {
    max-width: 1050px;
    margin: 0 auto;
    height: 64px;
    flex-direction: row-reverse;
    align-items: center;
  }
`

const AdditionalInfoContainer = styled.div`
  margin-top: 16px;
  @media screen and (min-width: 1050px) {
    margin-top: 48px;
  }
`

const CallToAction = styled.div`
  background-color: #f5f7fa;
  margin: 0 auto;
  cursor: pointer;
  @media screen and (min-width: 1050px) {
    min-height: 192px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & p {
    color: #3e4c59;
    font-size: 28px;
    margin: 0;
    font-family: "Avenir LT Std";
  }
`

const Footer = ({ location }) => {
  const [showFooter, setShowFooter] = useState(false)
  const { menu } = useContext(MenuContext)

  useEffect(() => {
    if (location.pathname === "/contattaci" || menu) {
      setShowFooter(false)
    } else {
      setShowFooter(true)
    }
  }, [location, menu])

  let iconStyle
  iconStyle = {
    size: "16",
    color: "#7B8794",
  }

  return (
    <>
      {showFooter ? (
        <>
          <Container>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Content>
                <LeftSide>
                  <div>
                    <StudioLogo thin>
                      Studio commercialisti associati
                    </StudioLogo>
                    <StudioLogo hasMargin>&mdash; Benetti Filippini</StudioLogo>
                  </div>
                  <AdditionalInfoContainer>
                    <AdditionalInfo>Rag. Mariacecilia Benetti</AdditionalInfo>
                    <AdditionalInfo>Dott.Ssa Ombretta Filippini</AdditionalInfo>
                    <AdditionalInfo>
                      Cod.Fisc e P.Iva 03301570176
                    </AdditionalInfo>
                  </AdditionalInfoContainer>
                </LeftSide>
                <div style={{ marginTop: "48px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <MapPin {...iconStyle} />
                    <Info>Via Malta n.8 - 25124 Brescia</Info>
                  </div>
                  <div style={{ display: "flex", marginTop: "24px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Phone {...iconStyle} />
                      <Info>030221067</Info>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "24px",
                      }}
                    >
                      <Printer {...iconStyle} />
                      <Info>030221067</Info>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "24px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Mail {...iconStyle} />
                    <Info>ceciliabenetti@benettifilippini.studio</Info>
                  </div>
                  <div
                    style={{
                      marginTop: "24px",
                      marginBottom: "48px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Mail {...iconStyle} />
                    <Info>ombrettafilippini@benettifilippini.studio</Info>
                  </div>
                </div>
              </Content>
              <BottomBar>
                <BottomBarContainer>
                  <BottomMenu>
                    <Link to="/">
                      <BottomMenuItem>Home</BottomMenuItem>
                    </Link>
                    <Link to="/servizi">
                      <BottomMenuItem>Servizi</BottomMenuItem>
                    </Link>
                    <Link to="/chisiamo">
                      <BottomMenuItem>Chi siamo</BottomMenuItem>
                    </Link>
                    <Link to="/circolari">
                      <BottomMenuItem>Circolari</BottomMenuItem>
                    </Link>
                    <Link to="/privacypolicy">
                      <BottomMenuItem>Privacy policy</BottomMenuItem>
                    </Link>
                  </BottomMenu>
                  <Copyright>
                    Copyright © 2020 - 2020 Studio commercialisti associati
                    Benetti Filippini
                  </Copyright>
                </BottomBarContainer>
              </BottomBar>
            </div>
          </Container>
        </>
      ) : null}
    </>
  )
}

export default Footer
