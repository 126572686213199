import React, { useContext, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { motion, AnimatePresence } from "framer-motion"
import Logo from "../assets/logoOrdine.svg"
import MenuIcon from "../assets/menu.svg"
import { X } from "react-feather"
import { Link } from "gatsby"
import MenuContext from "./menuContext"

const variants = {
  hidden: {
    opacity: 0,
    x: -5,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: -5,
  },
}

const NavBarMobile = props => {
  const [home, setHome] = useState(true)
  const [contact, setContact] = useState(false)
  const { menu, setMenu } = useContext(MenuContext)
  const location = props.location.pathname

  useEffect(() => {
    if (location === "/") {
      setHome(true)
      setContact(false)
    } else if (location === "/contattaci") {
      setContact(true)
      setHome(false)
    } else {
      setHome(false)
      setContact(false)
    }
  }, [location])

  return (
    <Nav>
      <LogosContainer>
        <AnimatePresence exitBeforeEnter>
          {!home && !contact && !menu && (
            <>
              <StudioLogoContainer
                initial="hidden"
                animate="visible"
                exit="exit"
                key="studioLogo"
                variants={variants}
              >
                <Link to="/" style={{ marginRight: "16px" }}>
                  <StudioLogo thin>Studio commercialisti associati</StudioLogo>
                  <StudioLogo style={{ marginTop: "-10px" }}>
                    &mdash; Benetti Filippini
                  </StudioLogo>
                </Link>
              </StudioLogoContainer>
              {!contact && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  key="logo"
                  variants={variants}
                >
                  <Logo />
                </motion.div>
              )}
            </>
          )}
          {home && !menu && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              key="logo"
              variants={variants}
            >
              <Logo />
            </motion.div>
          )}
        </AnimatePresence>
      </LogosContainer>
      {!contact && !menu && (
        <MenuIcon
          onClick={() => setMenu(true)}
          style={{ zIndex: "10", margin: "0 20px" }}
          tabIndex="0"
          role="button"
        />
      )}
      {contact && !menu && (
        <>
          <div></div>
          <Link to="/">
            <X
              size="24"
              color="#1F2933"
              style={{
                cursor: "pointer",
                marginRight: "20px",
              }}
            />
          </Link>
        </>
      )}
      {menu && (
        <>
          <div></div>
          <X
            size="24"
            color="#1F2933"
            onClick={() => setMenu(false)}
            style={{
              cursor: "pointer",
              marginRight: "20px",
            }}
          />
        </>
      )}
    </Nav>
  )
}

const Nav = styled.nav`
  align-items: center;
  border-bottom: 0.5px solid #cbd2d9;
  display: flex;
  flex-direction: row;
  height: 64px;
  justify-content: space-between;
  @media screen and (min-width: 1050px) {
    display: none;
  }
`

const StudioLogo = styled.h1`
  margin: 5px 0;
  font-size: 12px;
  color: #323f4b;
  font-weight: ${props => (props.thin ? "200" : "bold")};
`

const StudioLogoContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
`

const LogosContainer = styled.div`
  margin: 0 20px;
  display: flex;
  align-items: center;
`

export default NavBarMobile
