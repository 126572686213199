import React, { useContext } from "react"
import MenuContext from "./menuContext"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { Info, Home, Users, Download } from "react-feather"
import Logo from "../assets/logoOrdine.svg"

//TODO: Orchestrate the animation the right
//TODO: make the container component a motion.div
// that should make the other animation stagger

const variants = {
  hidden: { opacity: 0, y: 5 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 5 },
}

const MobileMenu = () => {
  const { setMenu } = useContext(MenuContext)
  let iconSize = "16"
  let iconColor = "#7B8794"

  return (
    <Container>
      <ItemContainer>
        <MenuItemsContainer>
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={variants}
            /* transition={{ delay: 0 }}
             */ style={{ flex: "1", marginTop: "24px" }}
          >
            <Home size={iconSize} color={iconColor} />
            <MenuItem>
              <Link
                to="/"
                activeStyle={activeStyle}
                onClick={() => setMenu(false)}
              >
                Home
              </Link>
            </MenuItem>
          </motion.div>
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            /* transition={{ delay: 0.1 }} */
            variants={variants}
            style={menuItemStyle}
          >
            <Info size={iconSize} color={iconColor} />
            <MenuItem>
              <Link
                to="/servizi"
                activeStyle={activeStyle}
                onClick={() => setMenu(false)}
              >
                Servizi
              </Link>
            </MenuItem>
          </motion.div>
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            /* transition={{ delay: 0.2 }} */
            variants={variants}
            style={menuItemStyle}
          >
            <Users size={iconSize} color={iconColor} />
            <MenuItem>
              <Link
                to="/chisiamo"
                activeStyle={activeStyle}
                onClick={() => setMenu(false)}
              >
                Chi siamo
              </Link>
            </MenuItem>
          </motion.div>
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            /* transition={{ delay: 0.3 }} */
            variants={variants}
            style={menuItemStyle}
          >
            <Download size={iconSize} color={iconColor} />
            <MenuItem>
              <Link
                to="/circolari"
                activeStyle={activeStyle}
                onClick={() => setMenu(false)}
              >
                Circolari
              </Link>
            </MenuItem>
          </motion.div>
          <Link
            to="/contattaci"
            style={{ flex: "1" }}
            onClick={() => setMenu(false)}
          >
            <Button>PRENOTA UN APPUNTAMENTO</Button>
          </Link>
        </MenuItemsContainer>
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          /* transition={{ delay: 0.3 }} */
          variants={bottomBar}
          style={{ marginTop: "24px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignSelf: "flex-end",
              justifySelf: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <div>
              <StudioLogo thin>Studio commercialisti associati</StudioLogo>
              <StudioLogo style={{ marginTop: "-6px" }}>
                &mdash; Benetti Filippini
              </StudioLogo>
            </div>
            <div>
              <Logo />
            </div>
          </div>
        </motion.div>
      </ItemContainer>
    </Container>
  )
}

const shadows = keyframes`
  from {
    box-shadow: none;
    opacity: 0;
  }

  to {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.15);
    opacity: 1;
  }
`

const Button = styled.div`
  align-items: center;
  background-color: #f5f7fa;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  color: #323f4b;
  display: flex;
  font-size: 12px;
  font-weight: 900;
  height: 42px;
  justify-content: center;
  margin-right: "16px";
  text-transform: uppercase;
  width: "100%";
  opacity: 0;
  transition: all 300ms ease;
  animation: ${shadows} 300ms ease forwards;
  animation-delay: 400ms;
  cursor: pointer;
  & a {
    transition: all 300ms ease;
  }
`

const ItemContainer = styled.div`
  margin: 24px 20px;
  flex-direction: column;
  height: 70vh;
  display: flex;
  justify-content: space-between;
  & a {
    text-decoration: none;
  }
`
const Container = styled.div`
  max-height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  @media screen and (min-width: 1050px) {
    display: none;
  }
`

const MenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;
`

const StudioLogo = styled.h1`
  margin: 5px 0;
  font-size: 14px;
  color: #3e4c59;
  font-weight: ${props => (props.thin ? "200" : "bold")};
`
const MenuItem = styled.span`
  font-size: 20px;
  font-weight: medium;
  margin-left: 16px;
  color: #3e4c59;
  & a {
    color: #3e4c59;
  }
`

const activeStyle = {
  fontWeight: 800,
}

const menuItemStyle = {
  flex: "1",
  marginTop: "-32px",
}

const bottomBar = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
  exit: { opacity: 0 },
}

export default MobileMenu
