import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import Button from "./button"
import LogoOrdine from "./logo.js"

const Container = styled.header`
  display: none;
  @media screen and (min-width: 1050px) {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    z-index: 1;
    display: block;
  }
`
const Content = styled.div`
  max-width: 1050px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  height: 96px;
  align-items: center;
  justify-content: space-between;
  & p {
    margin: 0;
  }
`

const MenuItem = styled.div`
  & a {
    text-decoration: none;
    color: #616e7c;
    font-size: 14px;
    font-weight: 700;
    transition: all 500ms ease;
    &:hover {
      color: #1f2933;
    }
  }
`

const activeStyle = {
  color: "#1f2933",
}

const HideOnMobile = styled.div`
  display: none;
  @media screen and (min-width: 1050px) {
    display: block;
  }
`

const StudioLogo = styled.h1`
  margin: 0 20px;
  font-size: 14px;
  color: #3e4c59;
  font-weight: ${props => (props.thin ? "200" : "bold")};
  @media screen and (min-width: 1050px) {
    font-size: 14px;
    margin: 0 24px 0 0;
  }
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

const NavBarDesktop = props => {
  const location = props.location.pathname
  let showLogo

  if (location === "/") {
    showLogo = false
  } else {
    showLogo = true
  }

  return (
    <Container>
      <Content>
        <Link to="/">
          <LogoContainer>
            {showLogo ? (
              <div>
                <StudioLogo thin>Studio commercialisti associati</StudioLogo>
                <StudioLogo style={{ marginTop: "-8px" }}>
                  &mdash; Benetti Filippini
                </StudioLogo>
              </div>
            ) : null}
            <LogoOrdine />
          </LogoContainer>
        </Link>

        <div
          style={{
            display: "flex",
            width: "35%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <MenuItem>
            <Link to="/servizi" activeStyle={activeStyle}>
              Servizi
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/chisiamo" activeStyle={activeStyle}>
              Chi siamo
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/circolari" activeStyle={activeStyle}>
              Circolari
            </Link>
          </MenuItem>
          <div style={{ width: "128px" }}>
            <Button primary isFullWidth>
              <Link to="/contattaci" style={{ color: "#f5f7fa" }}>
                Contattaci
              </Link>
            </Button>
          </div>
        </div>
      </Content>
    </Container>
  )
}

export default NavBarDesktop
