import React, { useContext } from "react"
import { motion, AnimatePresence } from "framer-motion"
import MenuContext from "./menuContext"
import MobileMenu from "./mobileMenu"

const variants = {
  hidden: {
    opacity: 0,
    y: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 0,
  },
}

const Transitions = ({ location, children }) => {
  const { menu } = useContext(MenuContext)

  return (
    <AnimatePresence exitBeforeEnter>
      {menu ? (
        <MobileMenu key="mobileMenu" />
      ) : (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={variants}
          key={location.pathname}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Transitions
