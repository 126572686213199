import React from "react"
import Layout from "./src/components/Layout"

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

const transitionDelay = 500

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  }
  return false
}
